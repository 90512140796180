import '../styles/SideBar.css';
import sideBarButton0 from '../assets/nav/nav-0.png';
import sideBarButtonFill0 from '../assets/nav/nav-0-hi.png';
import sideBarButton1 from '../assets/nav/nav-1.png';
import sideBarButtonFill1 from '../assets/nav/nav-1-hi.png';
import sideBarButton2 from '../assets/nav/nav-2.png';
import sideBarButtonFill2 from '../assets/nav/nav-2-hi.png';
import sideBarButton3 from '../assets/nav/nav-3.png';
import sideBarButtonFill3 from '../assets/nav/nav-3-hi.png';
import sideBarButton4 from '../assets/nav/nav-4.png';
import sideBarButtonFill4 from '../assets/nav/nav-4-hi.png';
import sideBarButton5 from '../assets/nav/nav-5.png';
import sideBarButtonFill5 from '../assets/nav/nav-5-hi.png';
import sideBarButton6 from '../assets/nav/nav-6.png';
import sideBarButtonFill6 from '../assets/nav/nav-6-hi.png';
import sideBarButton7 from '../assets/nav/nav-7.png';
import sideBarButtonFill7 from '../assets/nav/nav-7-hi.png';
import sideBarButton8 from '../assets/nav/nav-8.png';
import sideBarButtonFill8 from '../assets/nav/nav-8-hi.png';
import sideBarButton9 from '../assets/nav/nav-9.png';
import sideBarButtonFill9 from '../assets/nav/nav-9-hi.png';
import React,{useEffect} from 'react';


function SideBar() {
  
  useEffect(() => {
    
    
    const sideBarButtons = document.querySelectorAll('.side-bar-button');
    sideBarButtons.forEach((e,i) => {
      e.addEventListener('click',(e)=>{
        console.log("GOTO:"+i);
        let o;
        let y;
        switch(i){
          case 0:
            window.scrollTo(0,0);
            break;
          case 1:
            y = document.querySelector('.before').getBoundingClientRect().top + window.scrollY;
            console.log("scrollY:"+window.scrollY, "y:"+y)
            if(window.scrollY < y){ 
              o = window.innerHeight*.5;
            } else if(window.scrollY > y){
              o = window.innerHeight*-.5;
            } else {
              o = 0;
            }
            y+=o;
            window.scroll({ top: y, behavior: 'smooth'});
            break;
          case 2:
            y = document.querySelector('.about').getBoundingClientRect().top + window.scrollY;
            console.log("scrollY:"+window.scrollY, "y:"+y)
            if(window.scrollY < y){ 
              o = window.innerHeight*.5;
            } else if(window.scrollY > y){
              o = window.innerHeight*-.5;
            } else {
              o = 0;
            }
            y+=o;
            window.scroll({ top: y, behavior: 'smooth'});
            break;
          case 3:
            y = document.querySelector('.step-1').getBoundingClientRect().top + window.scrollY;
            console.log("scrollY:"+window.scrollY, "y:"+y)
            if(window.scrollY < y){ 
              o = window.innerHeight*.3;
            } else if(window.scrollY > y){
              o = window.innerHeight*-.7;
            } else {
              o = 0;
            }
            y+=o;
            window.scroll({ top: y, behavior: 'smooth'});
            break;
          case 4:
            y = document.querySelector('.step-2').getBoundingClientRect().top + window.scrollY;
            console.log("scrollY:"+window.scrollY, "y:"+y)
            if(window.scrollY < y){ 
              o = window.innerHeight*.5;
            } else if(window.scrollY > y){
              o = window.innerHeight*-.5;
            } else {
              o = 0;
            }
            y+=o;
            window.scroll({ top: y, behavior: 'smooth'});
            break;
          case 5:
            y = document.querySelector('.step-3').getBoundingClientRect().top + window.scrollY;
            console.log("scrollY:"+window.scrollY, "y:"+y)
            if(window.scrollY < y){ 
              o = window.innerHeight*.5;
            } else if(window.scrollY > y){
              o = window.innerHeight*-.5;
            } else {
              o = 0;
            }
            y+=o;
            window.scroll({ top: y, behavior: 'smooth'});
            break;
          case 6:
            y = document.querySelector('.step-4').getBoundingClientRect().top + window.scrollY;
            console.log("scrollY:"+window.scrollY, "y:"+y)
            if(window.scrollY < y){ 
              o = window.innerHeight*.5;
            } else if(window.scrollY > y){
              o = window.innerHeight*-.5;
            } else {
              o = 0;
            }
            y+=o;
            window.scroll({ top: y, behavior: 'smooth'});
            break;
          case 7:
            y = document.querySelector('.step-5').getBoundingClientRect().top + window.scrollY;
            console.log("scrollY:"+window.scrollY, "y:"+y)
            if(window.scrollY < y){ 
              o = window.innerHeight*.5;
            } else if(window.scrollY > y){
              o = window.innerHeight*-.5;
            } else {
              o = 0;
            }
            y+=o;
            window.scroll({ top: y, behavior: 'smooth'});
            break;
          case 8:     
            y = document.querySelector('.end').getBoundingClientRect().top + window.scrollY;
            console.log("scrollY:"+window.scrollY, "y:"+y)
            if(window.scrollY < y){ 
              o = window.innerHeight*.5;
            } else if(window.scrollY > y){
              o = window.innerHeight*-.5;
            } else {
              o = 0;
            }
            y+=o;
            window.scroll({ top: y, behavior: 'smooth'});
            break;
          case 9:
            y = document.querySelector('.gallery').getBoundingClientRect().top + window.scrollY + window.innerHeight*0;
            window.scroll({ top: y, behavior: 'smooth'});
            break;
        }

      });
    });

  }, [])

  const sideBarButtons = [
    sideBarButton0, 
    sideBarButton1, 
    sideBarButton2, 
    sideBarButton3, 
    sideBarButton4, 
    sideBarButton5, 
    sideBarButton6, 
    sideBarButton7, 
    sideBarButton8, 
    sideBarButton9, 
  ];

  const sideBarButtonFills = [
    sideBarButtonFill0, 
    sideBarButtonFill1, 
    sideBarButtonFill2, 
    sideBarButtonFill3, 
    sideBarButtonFill4, 
    sideBarButtonFill5, 
    sideBarButtonFill6, 
    sideBarButtonFill7, 
    sideBarButtonFill8, 
    sideBarButtonFill9,  
  ];

  return (
    <div className="side-bar">
       {[...Array(10)].map((x, i) =>
        <div key={i} className={`side-bar-button side-bar-button-`+i}>
          <img src={sideBarButtonFills[i]} className={`side-bar-button-fill`} />
          <img src={sideBarButtons[i]} className={`side-bar-button-outline`} />
        </div>
      )}
    </div>
  );
}

export default SideBar;