import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ReactGA from 'react-ga';

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.refresh();

ScrollTrigger.defaults({markers:false})


// highlight the sideBar nav item
const highlightNav = (e)=>{
  console.log("HIGHLIGHT NAV:", e);
  gsap.to('.side-bar-button-fill',{duration:.3,autoAlpha:0});
  gsap.to('.side-bar-button-' + e + ' .side-bar-button-fill',{duration:.3,autoAlpha:1});

  // track in GA wha the user has scrolled into
  let str;
  switch(e){
    case 0:
      str = 'landing';
      break;
    case 1:
      str = 'lets-cut-it';
      break;
    case 2:
      str = 'about';
      break;
    case 3:
      str = 'step-1';
      break;
    case 4:
      str = 'step-2';
      break;
    case 5:
      str = 'step-3';
      break;
    case 6:
      str = 'step-4';
      break;
    case 7:
      str = 'step-5';
      break;
    case 8:
      str = 'not-10-inches';
      break;
    case 9:
      str = 'gallery';
      break;
    default:
      break;
  }

  if(str){
    ReactGA.pageview(str);
  }


}


const animations = {

  createScrollTriggers(){
    gsap.set(".steps-videos", { yPercent:-50, top:"50%"}) 
    //ScrollTrigger.refresh();
    if ('scrollRestoration' in window.history) {
        window.history.scrollRestoration = 'manual';
    }
    window.scrollTo(0,0);
    
    // hack to handle iOS firing resize events when nav or address bar auto hide /show
    // only reresh scrollScritgger if the width also changed.
    let w = window.innerWidth;
    window.addEventListener('resize',(e)=>{
      if(w!=window.innerWidth){
        ScrollTrigger.refresh();
      }
    })


    // set this to occur later after everything loaded
    setTimeout( ()=>{
      // create an Observer instance
      const resizeObserver = new ResizeObserver(entries => {
        //console.log('here');
        ScrollTrigger.refresh();
        hideVideos();
      })

      // start observing a DOM node
      resizeObserver.observe(document.querySelector('.gallery .posts'));
    }, 8000);
    
    

    
    setTimeout(()=>{
      gsap.to('.scroll-icon-arrows',{rotation:1800, duration:10, ease:'none', scrollTrigger:{
        scrub:.1,
        trigger:'.landing',
        endTrigger: '.footer .lolLogo',
        start: 'top .1%',
        end: 'bottom 100%',
      }})

      gsap.fromTo('.scroll-icon',{autoAlpha:1}, {autoAlpha:0,duration:1,ease:'none',
      scrollTrigger:{
        scrub:.1,
        trigger:'.footer',
        start: 'top bottom',
        end: 'bottom bottom',
      }})
    },5000);


    function hideVideos(v){
      const videos = document.querySelectorAll('.step-video');
      const current = document.querySelector(v);
      videos.forEach((_v) => {
        if (_v !== current) {
          _v.style.visibility = 'hidden';
          try {
            _v.pause();
          } catch (e) {
          }
          setTimeout((e) => {
            _v.currentTime = 0;
          }, 100);
        }
      })
    }
    hideVideos();

    function playVideo(v){
      hideVideos(v);
      const video = document.querySelector(v);
      video.currentTime=0;
      video.style.visibility = 'visible';
      video.play();
      
    }

    // video loop handlers
    const step1Video = document.querySelector('.step-1-media');
    step1Video.addEventListener('ended',(e)=>{
      playVideo('.step-1-media-loop');
    });
    const step2Video = document.querySelector('.step-2-media');
    step2Video.addEventListener('ended',(e)=>{
      playVideo('.step-2-media-loop');
    });
    const step3Video = document.querySelector('.step-3-media');
    step3Video.addEventListener('ended',(e)=>{
      playVideo('.step-3-media-loop');
    });
    const step4Video = document.querySelector('.step-4-media');
    step4Video.addEventListener('ended',(e)=>{
      playVideo('.step-4-media-loop');
    });
    const step5Video = document.querySelector('.step-5-media');
    step5Video.addEventListener('ended',(e)=>{
      playVideo('.step-5-media-loop');
    });


    // landing
    const tl = gsap.timeline({paused:true});
    tl.to(['.landing h1','.landing h2', '.landing p'],{ y:-40, stagger:.25, autoAlpha:0, duration:.25},0);
    tl.to(['.landing .media'],{ y:-40, autoAlpha:0, duration:.75},0);

    ScrollTrigger.create({
      animation:tl,
      trigger:'.landing',
      scrub:.1,
      start:"top .1%",
      end:"+=90%",
      pin:true,
      pinSpacing:false,
    })

   
  
    // before
    const tl2 = gsap.timeline({paused:true});
    tl2.from(['.before h1','.before p'],{ y:40, stagger:.25, autoAlpha:0, duration:.25},"+=.1");
    tl2.from(['.before .media'],{ scale:2, autoAlpha:0, y:10,duration:.5},"-=.5");
    tl2.to(['.before'],{ autoAlpha:1,duration:.5,ease:"none"},"+=1");

    ScrollTrigger.create({
      animation:tl2,
      trigger:'.before',
      scrub:.1,
      start:"top top",
      end:"+=100%",
      pin:true,
      pinSpacing:true,
    })
  

    // about
    const tl3 = gsap.timeline({paused:true});
    tl3.from(['.about .title-icon', '.about h1','.about p'],{ y:40, stagger:.25, autoAlpha:0, duration:.25},"+=0");
    tl3.from(['.about .media'],{ autoAlpha:0, y:20,duration:.5},"-=.5");
    tl3.to(['.about'],{ autoAlpha:1,duration:.5,ease:"none"},"+=1");
    tl3.to(['.about .title-icon','.about h1','.about h2', '.about p'],{ y:-40, stagger:.25, autoAlpha:0, duration:.25},"-=.25");
    tl3.to(['.about .media'],{ autoAlpha:0, y:-40,duration:.75},"-=.25");

    ScrollTrigger.create({
      trigger:'.about',
      scrub:.1,
      start:"top top",
      end:"+=100%",
      pin:true,
      pinSpacing:true,
    })

    ScrollTrigger.create({
      animation:tl3,
      trigger:'.about',
      scrub:.1,
      start:"top 40%",
      end:"+=200%",
    })


    // step 1
    let step1Dir = null;
    const tl4 = gsap.timeline({paused:true});
    
    tl4.call(()=>{
      playVideo(".step-1-media");
      const v = document.querySelector(".step-1-media");
      v.style.visibility='visible';
      v.currentTime=0;
      v.play();
      v.pause();
    },null,0)
    
    tl4.from(['.step-1 .title-icon', '.step-1 h1', '.step-1 p'],{ stagger:.25, y:20, autoAlpha:0, duration:.5,ease:"none"},0);
    tl4.from(['.steps-videos'],{ autoAlpha:0, y:"100vh", duration:1.5,ease:"none"},0);
    tl4.call(() =>{
      if(step1Dir>0){
        playVideo(".step-1-media");
      } else {
        playVideo(".step-1-media-loop");
      }
      
    },null);
    //tl4.to(['.step-1'],{ autoAlpha:1,duration:.5,ease:"none"},"+=1");
    tl4.to(['.step-1 .title-icon', '.step-1 h1', '.step-1 p'],{ stagger:.25, y:-20, autoAlpha:0, duration:.5,ease:"none"},"+=1");

    tl4.call(() =>{
      if(step1Dir<0){
        playVideo(".step-1-media-loop");
      }
      
    },null,"-=.1");

   
    ScrollTrigger.create({
      trigger:'.step-1',
      scrub:.1,
      start:"top top",
      end:"+=100%",
      pin:true,
      pinSpacing:false,
    })

    ScrollTrigger.create({
      animation:tl4,
      trigger:'.step-1',
      scrub:.1,
      start:"top 40%",
      end:"+=100%",
      onUpdate: self => {
         step1Dir = self.direction;
      }
    })



    // step 2
    let step2Dir = null;
    const tl5 = gsap.timeline({paused:true});
    tl5.from(['.step-2 .title-icon', '.step-2 h1','.step-2 h2', '.step-2 p'],{ stagger:.25, y:20, autoAlpha:0, duration:.5,ease:"none"},"0");
    tl5.call(() =>{
      if(step2Dir>0){
        playVideo(".step-2-media");
      } else {
        playVideo(".step-2-media-loop");
      }
      
    },null);
    tl5.to(['.step-2 .title-icon', '.step-2 h1','.step-2 h2', '.step-2 p'],{ stagger:.25, y:-20, autoAlpha:0, duration:.5,ease:"none"},"+=1");

    tl5.call(() =>{
      if(step2Dir<0){
        playVideo(".step-2-media-loop");
      }
      
    },null,"-=.1");

    ScrollTrigger.create({
      animation:tl5,
      trigger:'.step-2',
      scrub:.1,
      start:"top top",
      end:"+=100%",
      pin:true,
      pinSpacing:false,
      onUpdate: self => {
        step2Dir = self.direction;
      }
    })




    // step 3
    let step3Dir = null;
    const tl6 = gsap.timeline({paused:true});
    tl6.addLabel("step-3");
    tl6.from(['.step-3 .title-icon', '.step-3 h1','.step-3 h2', '.step-3 p'],{ stagger:.25, y:20, autoAlpha:0, duration:.5,ease:"none"},"0");
    tl6.call(() =>{
      if(step3Dir>0){
        playVideo(".step-3-media");
      } else {
        playVideo(".step-3-media-loop");
      }
      
    },null);
    tl6.to(['.step-3 .title-icon', '.step-3 h1','.step-3 h2', '.step-3 p'],{ stagger:.25, y:-20, autoAlpha:0, duration:.5,ease:"none"},"+=1");
    tl6.call(() =>{
      if(step3Dir<0){
        playVideo(".step-3-media-loop");
      }
      
    },null,"-=.1");

    ScrollTrigger.create({
      animation:tl6,
      trigger:'.step-3',
      scrub:.1,
      start:"top top",
      end:"+=100%",
      pin:true,
      pinSpacing:false,
      onUpdate: self => {
        step3Dir = self.direction;
      }
    })




    // step 4
    let step4Dir = null;
    const tl7 = gsap.timeline({paused:true});
    tl7.addLabel("step-4");
    tl7.from(['.step-4 .title-icon', '.step-4 h1','.step-4 h2', '.step-4 h3','.step-4 p'],{ stagger:.25, y:20, autoAlpha:0, duration:.5,ease:"none"},"-=.25");
    tl7.call(() =>{
      if(step4Dir>0){
        playVideo(".step-4-media");
      } else {
        playVideo(".step-4-media-loop");
      }
      
    },null);
    tl7.to(['.step-4 .title-icon', '.step-4 h1','.step-4 h2','.step-4 h3', '.step-4 p'],{ stagger:.25, y:-20, autoAlpha:0, duration:.5,ease:"none"},"+=1");
    tl7.call(() =>{
      if(step4Dir<0){
        playVideo(".step-4-media-loop");
      }
      
    },null,"-=.1");

    ScrollTrigger.create({
      animation:tl7,
      trigger:'.step-4',
      scrub:.1,
      start:"top top",
      end:"+=100%",
      pin:true,
      pinSpacing:false,
      onUpdate: self => {
        step4Dir = self.direction;
      }
    })



    // step 5
    let step5Dir = null;
    const tl8 = gsap.timeline({paused:true});
    tl8.addLabel("step-5");
    tl8.from(['.step-5 .title-icon', '.step-5 h1','.step-5 h2', '.step-5 p', '.step-5 .share-links'],{ stagger:.25, y:20, autoAlpha:0, duration:.5,ease:"none"},"-=0");
    tl8.call(() =>{
      if(step5Dir>0){
        playVideo(".step-5-media");
      } else {
        playVideo(".step-5-media-loop");
      }
      
    },null);
    tl8.to(['.step-5 .title-icon', '.step-5 h1','.step-5 h2', '.step-5 p', '.step-5 .share-links'],{ stagger:.25, y:-20, autoAlpha:0, duration:.5,ease:"none"},"+=1");
    tl8.call(() =>{
      if(step5Dir<0){
        playVideo(".step-5-media-loop");
      }
      
    },null,"-=.1");
    tl8.fromTo(['.steps-videos'],{autoAlpha:1},{ y:-100, autoAlpha:0,duration:.5,ease:"power1.inOut"},"-=1");


    ScrollTrigger.create({
      trigger:'.step-5',
      scrub:.1,
      start:"top top",
      end:"+=100%",
      pin:true,
      pinSpacing:true,
      onUpdate: self => {
        step5Dir = self.direction;
      }
    })

    ScrollTrigger.create({
      animation:tl8,
      trigger:'.step-5',
      scrub:.1,
      start:"top top",
      end:"+=150%",
    })


    // end
    const tl9 = gsap.timeline({paused:true});
    tl9.from(['.end .title-icon', '.end h1','.end p'],{ y:40, stagger:.25, autoAlpha:0, duration:.25},"+=0");
    tl9.addLabel("end");
    tl9.from(['.end .media'],{ autoAlpha:0, y:20,duration:.5},"-=.5");
    tl9.to(['.end'],{ autoAlpha:1,duration:.5,ease:"none"},"+=.5");
    tl9.to(['.end .title-icon','.end h1','.end h2', '.end p'],{ y:-40, stagger:.25, autoAlpha:0, duration:.25},"-=.25");
    tl9.to(['.end .media'],{ autoAlpha:0, y:-40,duration:.75},"-=.25");

    ScrollTrigger.create({
      trigger:'.end',
      scrub:.1,
      start:"top top",
      end:"+=100%",
      pin:true,
      pinSpacing:true,
    })

    ScrollTrigger.create({
      animation:tl9,
      trigger:'.end',
      scrub:.1,
      start:"top 40%",
      end:"+=200%",
    })


    // listen for about scrolls into view
    ScrollTrigger.create({
      trigger: document.querySelector('.landing'),
      start:"top .1%",
      onEnter: ()=>{ highlightNav(0);},
      onEnterBack: ()=>{ highlightNav(0);},
    });
    ScrollTrigger.create({
      trigger: document.querySelector('.before'),
      start:"top top",
      onEnter: ()=>{ highlightNav(1);},
      onEnterBack: ()=>{ highlightNav(1);},
    });
    ScrollTrigger.create({
      trigger: document.querySelector('.about'),
      start:"top top",
      onEnter: ()=>{ highlightNav(2);},
      onEnterBack: ()=>{ highlightNav(2);},
    });
    ScrollTrigger.create({
      trigger: document.querySelector('.step-1'),
      start:"top top",
      onEnter: ()=>{ highlightNav(3);},
      onEnterBack: ()=>{ highlightNav(3);},
    });
    ScrollTrigger.create({
      trigger: document.querySelector('.step-2'),
      start:"top top",
      onEnter: ()=>{ highlightNav(4);},
      onEnterBack: ()=>{ highlightNav(4);},
    });
    ScrollTrigger.create({
      trigger: document.querySelector('.step-3'),
      start:"top top",
      onEnter: ()=>{ highlightNav(5);},
      onEnterBack: ()=>{ highlightNav(5);},
    });
    ScrollTrigger.create({
      trigger: document.querySelector('.step-4'),
      start:"top top",
      onEnter: ()=>{ highlightNav(6);},
      onEnterBack: ()=>{ highlightNav(6);},
    });
    ScrollTrigger.create({
      trigger: document.querySelector('.step-5'),
      start:"top top",
      onEnter: ()=>{ highlightNav(7);},
      onEnterBack: ()=>{ highlightNav(7);},
    });
    ScrollTrigger.create({
      trigger: document.querySelector('.end'),
      start:"top top",
      onEnter: ()=>{ highlightNav(8);},
      onEnterBack: ()=>{ highlightNav(8);},
    });
    ScrollTrigger.create({
      trigger: document.querySelector('.gallery'),
      start:"top .1%",
      onEnter: ()=>{ highlightNav(9);},
      onEnterBack: ()=>{ highlightNav(9);},
    });



    

    //  hearts falling aniamtion

    w = window.innerWidth
    var h = window.innerHeight,
    container = document.querySelector(".about .hearts"),
    sizes = ["Small", "Medium", "Large"],
    types = ["round", "star", "real", "sharp", "ring"],
    snowflakes = 10;

    for (var i = 0; i < snowflakes; i++) {
      var snowflakeDiv = document.createElement('div');
      var sizeIndex = Math.ceil(Math.random() * 3) -1; //get random number between 0 and 2
      var size = sizes[sizeIndex]; //get random size
      var typeIndex = Math.ceil(Math.random() * 5) -1;
      var type = types[typeIndex];
      gsap.set(snowflakeDiv, {attr: {class: type + size}, x: R(0,w), y: R(-200,-150) });
      container.appendChild(snowflakeDiv);
      snowing(snowflakeDiv);
    }
        
    function snowing(element) {
      gsap.to(element, R(5,12), {y: h+100, ease: "none", repeat:-1, delay: -15});
      gsap.to(element, R(4,8), {x: '+=100', repeat: -1, yoyo: true, ease: "sine.inOut"});
      gsap.to(element, R(2,8), {rotation: R(0,360), repeat: -1, yoyo: true, ease:"sine.inOut", delay: -5});
    };

    function R(min,max) {
      return min + Math.random() * (max-min)
    };

    

  },




  createMobileScrollTriggers(){
    gsap.set(".steps-videos", { yPercent:-50, top:"50%"}) 
    //ScrollTrigger.refresh();
    if ('scrollRestoration' in window.history) {
        window.history.scrollRestoration = 'manual';
    }
    window.scrollTo(0,0);
    
    // hack to handle iOS firing resize events when nav or address bar auto hide /show
    // only reresh scrollScritgger if the width also changed.
    let w = window.innerWidth;
    window.addEventListener('resize',(e)=>{
      if(w!=window.innerWidth){
        ScrollTrigger.refresh();
      }
    })


    // set this to occur later after everything loaded
    setTimeout( ()=>{
      // create an Observer instance
      const resizeObserver = new ResizeObserver(entries => {
        //console.log('here');
        ScrollTrigger.refresh();
        hideVideos();
      })

      // start observing a DOM node
      resizeObserver.observe(document.querySelector('.gallery .posts'));
    }, 8000);
    
    

    // scroll icon anaimtions
    setTimeout(()=>{
      gsap.to('.scroll-icon-arrows',{rotation:1800, duration:10, ease:'none', scrollTrigger:{
        scrub:.1,
        trigger:'.landing',
        endTrigger: '.footer .lolLogo',
        start: 'top .1%',
        end: 'bottom 100%',
      }})

      gsap.fromTo('.scroll-icon',{autoAlpha:1}, {autoAlpha:0,duration:1,ease:'none',
      scrollTrigger:{
        scrub:.1,
        trigger:'.step-3',
        start: 'top bottom',
        end: 'bottom bottom',
      }})

      gsap.fromTo('.scroll-icon',{autoAlpha:0}, {autoAlpha:1,duration:1,ease:'none',
      scrollTrigger:{
        scrub:.1,
        trigger:'.end',
        start: 'top bottom',
        end: 'bottom 30%',
      }})

      gsap.fromTo('.scroll-icon',{autoAlpha:1}, {autoAlpha:0,duration:1,ease:'none',
      scrollTrigger:{
        scrub:.1,
        trigger:'.footer',
        start: 'top bottom',
        end: 'bottom bottom',
      }})
    },5000);


    function hideVideos(){
      const videos = document.querySelectorAll('.step-video');
      videos.forEach((_v) => { 
        _v.style.visibility='hidden';
        try{_v.pause();}catch(e){}
        setTimeout((e)=>{_v.currentTime=0;},100);
      })
    }
    hideVideos();

    function playVideo(v){
      hideVideos();
      const video =  document.querySelector(v);
      video.currentTime=0;
      video.style.visibility = 'visible';
      video.play();
      
    }

    // video loop handlers
    const step1Video = document.querySelector('.step-1-media');
    step1Video.addEventListener('ended',(e)=>{
      playVideo('.step-1-media-loop');
    });
    const step2Video = document.querySelector('.step-2-media');
    step2Video.addEventListener('ended',(e)=>{
      playVideo('.step-2-media-loop');
    });
    const step3Video = document.querySelector('.step-3-media');
    step3Video.addEventListener('ended',(e)=>{
      playVideo('.step-3-media-loop');
    });
    const step4Video = document.querySelector('.step-4-media');
    step4Video.addEventListener('ended',(e)=>{
      playVideo('.step-4-media-loop');
    });
    const step5Video = document.querySelector('.step-5-media');
    step5Video.addEventListener('ended',(e)=>{
      playVideo('.step-5-media-loop');
    });


    // landing
    const tl = gsap.timeline({paused:true});
    tl.to(['.landing h1','.landing h2', '.landing p'],{ y:-40, stagger:.25, autoAlpha:0, duration:.25},0);
    tl.to(['.landing .media'],{ y:-40, autoAlpha:0, duration:.75},.5);

    ScrollTrigger.create({
      animation:tl,
      trigger:'.landing',
      scrub:.1,
      start:"top .1%",
      end:"+=100%",
      //pin:true,
      //pinSpacing:false,
    })

   
  
    // before
    const tl2 = gsap.timeline({paused:true});
    tl2.from(['.before h1','.before p'],{ y:40, stagger:.25, autoAlpha:0, duration:.25},0);
    tl2.from(['.before .media'],{ scale:2, autoAlpha:0, y:10,duration:.5},"0");
    tl2.to(['.before'],{ autoAlpha:1,duration:.5,ease:"none"},"1");

    ScrollTrigger.create({
      animation:tl2,
      trigger:'.before',
      scrub:.1,
      start:"top 30%",
      end:"+=100%",
      //pin:true,
      //pinSpacing:true,
    })
  

    // about
    const tl3 = gsap.timeline({paused:true});
    tl3.from(['.about .title-icon', '.about h1','.about p'],{ y:40, stagger:.25, autoAlpha:0, duration:.25},"+=0");
    tl3.from(['.about .media'],{ autoAlpha:0, y:20,duration:.5},"-=.5");
    tl3.to(['.about'],{ autoAlpha:1,duration:.5,ease:"none"},"+=1");
    tl3.to(['.about .title-icon','.about h1','.about h2', '.about p'],{ y:-40, stagger:.25, autoAlpha:0, duration:.25},"-=.25");
    tl3.to(['.about .media'],{ autoAlpha:0, y:-40,duration:.75},"-=.25");

    ScrollTrigger.create({
      trigger:'.about',
      scrub:.1,
      start:"top top",
      end:"+=100%",
      //pin:true,
      //pinSpacing:true,
    })

    ScrollTrigger.create({
      animation:tl3,
      trigger:'.about',
      scrub:.1,
      start:"top 40%",
      end:"+=200%",
    })


    // step 1
    let step1Dir = null;
    const tl4 = gsap.timeline({paused:true});
    
    tl4.call(()=>{
      playVideo(".step-1-media");
      const v = document.querySelector(".step-1-media");
      v.style.visibility='visible';
      v.currentTime=0;
      v.play();
      v.pause();
    },null,0)
    
    tl4.from(['.step-1 .title-icon', '.step-1 h1', '.step-1 p'],{ stagger:.25, y:20, autoAlpha:0, duration:.5,ease:"none"},0);
    tl4.from(['.steps-videos'],{ autoAlpha:0, y:"100vh", duration:1.5,ease:"none"},0);
    tl4.call(() =>{
      if(step1Dir>0){
        playVideo(".step-1-media");
      } else {
        playVideo(".step-1-media-loop");
      }
      
    },null);
    //tl4.to(['.step-1'],{ autoAlpha:1,duration:.5,ease:"none"},"+=1");
    tl4.to(['.step-1 .title-icon', '.step-1 h1', '.step-1 p'],{ stagger:.25, y:-20, autoAlpha:0, duration:.5,ease:"none"},"+=1");

    tl4.call(() =>{
      if(step1Dir<0){
        playVideo(".step-1-media-loop");
      }
      
    },null,"-=.1");

   
    ScrollTrigger.create({
      trigger:'.step-1',
      scrub:.3,
      start:"top top",
      end:"+=100%",
      pin:true,
      pinSpacing:false,
    })

    ScrollTrigger.create({
      animation:tl4,
      trigger:'.step-1',
      scrub:.3,
      start:"top 40%",
      end:"+=100%",
      onUpdate: self => {
         step1Dir = self.direction;
      }
    })



    // step 2
    let step2Dir = null;
    const tl5 = gsap.timeline({paused:true});
    tl5.from(['.step-2 .title-icon', '.step-2 h1','.step-2 h2', '.step-2 p'],{ stagger:.25, y:20, autoAlpha:0, duration:.5,ease:"none"},"0");
    tl5.call(() =>{
      if(step2Dir>0){
        playVideo(".step-2-media");
      } else {
        playVideo(".step-2-media-loop");
      }
      
    },null);
    tl5.to(['.step-2 .title-icon', '.step-2 h1','.step-2 h2', '.step-2 p'],{ stagger:.25, y:-20, autoAlpha:0, duration:.5,ease:"none"},"+=1");

    tl5.call(() =>{
      if(step2Dir<0){
        playVideo(".step-2-media-loop");
      }
      
    },null,"-=.1");

    ScrollTrigger.create({
      animation:tl5,
      trigger:'.step-2',
      scrub:.1,
      start:"top top",
      end:"+=100%",
      pin:true,
      pinSpacing:false,
      onUpdate: self => {
        step2Dir = self.direction;
      }
    })




    // step 3
    let step3Dir = null;
    const tl6 = gsap.timeline({paused:true});
    tl6.addLabel("step-3");
    tl6.from(['.step-3 .title-icon', '.step-3 h1','.step-3 h2', '.step-3 p'],{ stagger:.25, y:20, autoAlpha:0, duration:.5,ease:"none"},"0");
    tl6.call(() =>{
      if(step3Dir>0){
        playVideo(".step-3-media");
      } else {
        playVideo(".step-3-media-loop");
      }
      
    },null);
    tl6.to(['.step-3 .title-icon', '.step-3 h1','.step-3 h2', '.step-3 p'],{ stagger:.25, y:-20, autoAlpha:0, duration:.5,ease:"none"},"+=1");
    tl6.call(() =>{
      if(step3Dir<0){
        playVideo(".step-3-media-loop");
      }
      
    },null,"-=.1");

    ScrollTrigger.create({
      animation:tl6,
      trigger:'.step-3',
      scrub:.1,
      start:"top top",
      end:"+=100%",
      pin:true,
      pinSpacing:false,
      onUpdate: self => {
        step3Dir = self.direction;
      }
    })




    // step 4
    let step4Dir = null;
    const tl7 = gsap.timeline({paused:true});
    tl7.addLabel("step-4");
    tl7.from(['.step-4 .title-icon', '.step-4 h1','.step-4 h2', '.step-4 h3','.step-4 p'],{ stagger:.25, y:20, autoAlpha:0, duration:.5,ease:"none"},"-=.25");
    tl7.call(() =>{
      if(step4Dir>0){
        playVideo(".step-4-media");
      } else {
        playVideo(".step-4-media-loop");
      }
      
    },null);
    tl7.to(['.step-4 .title-icon', '.step-4 h1','.step-4 h2','.step-4 h3', '.step-4 p'],{ stagger:.25, y:-20, autoAlpha:0, duration:.5,ease:"none"},"+=1");
    tl7.call(() =>{
      if(step4Dir<0){
        playVideo(".step-4-media-loop");
      }
      
    },null,"-=.1");

    ScrollTrigger.create({
      animation:tl7,
      trigger:'.step-4',
      scrub:.1,
      start:"top top",
      end:"+=100%",
      pin:true,
      pinSpacing:false,
      onUpdate: self => {
        step4Dir = self.direction;
      }
    })



    // step 5
    let step5Dir = null;
    const tl8 = gsap.timeline({paused:true});
    tl8.addLabel("step-5");
    tl8.from(['.step-5 .title-icon', '.step-5 h1','.step-5 h2', '.step-5 p', '.step-5 .share-links'],{ stagger:.25, y:20, autoAlpha:0, duration:.5,ease:"none"},"-=0");
    tl8.call(() =>{
      if(step5Dir>0){
        playVideo(".step-5-media");
      } else {
        playVideo(".step-5-media-loop");
      }
      
    },null);
    tl8.to(['.step-5 .title-icon', '.step-5 h1','.step-5 h2', '.step-5 p', '.step-5 .share-links'],{ stagger:.25, y:-20, autoAlpha:0, duration:.5,ease:"none"},"+=1");
    tl8.call(() =>{
      if(step5Dir<0){
        playVideo(".step-5-media-loop");
      }
      
    },null,"-=.1");
    tl8.fromTo(['.steps-videos'],{autoAlpha:1},{ y:-100, autoAlpha:0,duration:.5,ease:"power1.inOut"},"-=1");


    ScrollTrigger.create({
      trigger:'.step-5',
      scrub:.1,
      start:"top top",
      end:"+=100%",
      pin:true,
      pinSpacing:true,
      onUpdate: self => {
        step5Dir = self.direction;
      }
    })

    ScrollTrigger.create({
      animation:tl8,
      trigger:'.step-5',
      scrub:.1,
      start:"top top",
      end:"+=150%",
    })


    // end
    const tl9 = gsap.timeline({paused:true});
    tl9.from(['.end .title-icon', '.end h1','.end p'],{ y:40, stagger:.25, autoAlpha:0, duration:.25},"+=0");
    tl9.addLabel("end");
    tl9.from(['.end .media'],{ autoAlpha:0, y:20,duration:.5},"-=.5");
    tl9.to(['.end'],{ autoAlpha:1,duration:.5,ease:"none"},"+=.5");
    tl9.to(['.end .title-icon','.end h1','.end h2', '.end p'],{ y:-40, stagger:.25, autoAlpha:0, duration:.25},"-=.25");
    tl9.to(['.end .media'],{ autoAlpha:0, y:-40,duration:.75},"-=.25");

    ScrollTrigger.create({
      trigger:'.end',
      scrub:.1,
      start:"top top",
      end:"+=100%",
      //pin:true,
      //pinSpacing:true,
    })

    ScrollTrigger.create({
      animation:tl9,
      trigger:'.end',
      scrub:.1,
      start:"top 40%",
      end:"+=200%",
    })

    // listen for about scrolls into view
    ScrollTrigger.create({
      trigger: document.querySelector('.landing'),
      start:"top .1%",
      onEnter: ()=>{ highlightNav(0);},
      onEnterBack: ()=>{ highlightNav(0);},
    });
    ScrollTrigger.create({
      trigger: document.querySelector('.before'),
      start:"top top",
      onEnter: ()=>{ highlightNav(1);},
      onEnterBack: ()=>{ highlightNav(1);},
    });
    ScrollTrigger.create({
      trigger: document.querySelector('.about'),
      start:"top top",
      onEnter: ()=>{ highlightNav(2);},
      onEnterBack: ()=>{ highlightNav(2);},
    });
    ScrollTrigger.create({
      trigger: document.querySelector('.step-1'),
      start:"top top",
      onEnter: ()=>{ highlightNav(3);},
      onEnterBack: ()=>{ highlightNav(3);},
    });
    ScrollTrigger.create({
      trigger: document.querySelector('.step-2'),
      start:"top top",
      onEnter: ()=>{ highlightNav(4);},
      onEnterBack: ()=>{ highlightNav(4);},
    });
    ScrollTrigger.create({
      trigger: document.querySelector('.step-3'),
      start:"top top",
      onEnter: ()=>{ highlightNav(5);},
      onEnterBack: ()=>{ highlightNav(5);},
    });
    ScrollTrigger.create({
      trigger: document.querySelector('.step-4'),
      start:"top top",
      onEnter: ()=>{ highlightNav(6);},
      onEnterBack: ()=>{ highlightNav(6);},
    });
    ScrollTrigger.create({
      trigger: document.querySelector('.step-5'),
      start:"top top",
      onEnter: ()=>{ highlightNav(7);},
      onEnterBack: ()=>{ highlightNav(7);},
    });
    ScrollTrigger.create({
      trigger: document.querySelector('.end'),
      start:"top top",
      onEnter: ()=>{ highlightNav(8);},
      onEnterBack: ()=>{ highlightNav(8);},
    });
    ScrollTrigger.create({
      trigger: document.querySelector('.gallery'),
      start:"top .1%",
      onEnter: ()=>{ highlightNav(9);},
      onEnterBack: ()=>{ highlightNav(9);},
    });



    

    //  hearts falling aniamtion

    w = window.innerWidth
    var h = window.innerHeight,
    container = document.querySelector(".about .hearts"),
    sizes = ["Small", "Medium", "Large"],
    types = ["round", "star", "real", "sharp", "ring"],
    snowflakes = 10;

    for (var i = 0; i < snowflakes; i++) {
      var snowflakeDiv = document.createElement('div');
      var sizeIndex = Math.ceil(Math.random() * 3) -1; //get random number between 0 and 2
      var size = sizes[sizeIndex]; //get random size
      var typeIndex = Math.ceil(Math.random() * 5) -1;
      var type = types[typeIndex];
      gsap.set(snowflakeDiv, {attr: {class: type + size}, x: R(0,w), y: R(-200,-150) });
      container.appendChild(snowflakeDiv);
      snowing(snowflakeDiv);
    }
        
    function snowing(element) {
      gsap.to(element, R(5,12), {y: h+100, ease: "none", repeat:-1, delay: -15});
      gsap.to(element, R(4,8), {x: '+=100', repeat: -1, yoyo: true, ease: "sine.inOut"});
      gsap.to(element, R(2,8), {rotation: R(0,360), repeat: -1, yoyo: true, ease:"sine.inOut", delay: -5});
    };

    function R(min,max) {
      return min + Math.random() * (max-min)
    };

    

  },

  playTransitionToVideo(){

    const tl = gsap.timeline({paused:true});
    const el = document.querySelector('.intro-splash');
    const elVideo = document.querySelector('.intro-video');
    const screen = document.querySelector('.intro-screen');
    const skipButton = document.querySelector('.intro-skip-button');
    const soundButton = document.querySelector('.intro-sound-button');
    const playIcon = document.querySelector('.intro-play-icon');
    
    tl.to(playIcon,{ autoAlpha:0, duration:.5 , ease: 'none'},0);
    tl.to(screen,{ autoAlpha:0, duration:.5 , ease: 'power1.in'},"-=.5");
    
    tl.to(el,{scale:3, autoAlpha:0, duration:1.5 , ease: 'power1.in'},0);
    tl.from(elVideo,{scale:.5, autoAlpha:0, duration:1.5 , ease: 'power1.inOut'},0);

    tl.fromTo(soundButton,{y:100,autoAlpha:0},{y:0,autoAlpha:1,duration:.7,ease:'power1.out'},2.0);
    tl.fromTo(skipButton,{y:100,autoAlpha:0},{y:0,autoAlpha:1,duration:.7,ease:'power1.out'},2.25);
    tl.play();
  },

  playTransitionToLanding(delay = 0){
    const el = document.querySelector('.intro-video');
    const el2 = document.querySelector('.intro');
    const tl = gsap.timeline({paused:true});

    tl.to(el,{autoAlpha:0, duration:1}, delay);
    tl.to(el2,{autoAlpha:0, zIndex:0, duration:1}, delay);
    tl.call(this.playLandingAnimation,[.75], delay);
    tl.play(0);
  },

  playLandingAnimation(delay = 0){
    if(!this.tlLandingAnimation){
      let tl = this.tlLandingAnimation = gsap.timeline({ paused:true });
      tl.fromTo(['.landing h1', '.landing h2', '.landing p'],{autoAlpha:0, y:50 },{stagger: 0.25, autoAlpha:1, y:0, duration:1, ease:'power1.out'}, 0);
      tl.fromTo('.landing .media',{autoAlpha:0, x:20}, {autoAlpha:1, x:0, duration:1, ease:"power1.inOut"},0);

      tl.fromTo('.header a',{autoAlpha:0, y:-20 },{stagger: 0.25, autoAlpha:1, y:0, duration:.5, ease:'power1.out'}, .5);
      
      //TODO: stagger in all the dots
      tl.from('.side-bar .side-bar-button',{stagger: {each:.1,from:"start" }, autoAlpha:0, x:-20 }, 1.);

      tl.fromTo('.scroll-icon',{autoAlpha:0, y:100 },{autoAlpha:1, y:0, duration:1, ease:'power1.out'}, 1.5);

      tl.to('.scroll-icon-arrows',{rotation:720, duration:2, },"-=2.0");

    }
    this.tlLandingAnimation.delay( delay ).restart(true);

  },

  
}

// tmp testing controls
document.addEventListener('keyup', (e) => {
  console.log(e)
  switch(e.key){
    case "1":
      animations.playLandingAnimation(0);
      break;
    default:
      break;
  }
})


export default animations;
