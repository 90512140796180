import './styles/App.css';
import Intro from './components/Intro';
import Landing from './components/Landing';
import Before from './components/Before';
import About from './components/About';
import Steps from './components/Steps';
import End from './components/End';
import Gallery from './components/Gallery';
import Header from './components/Header';
import ScrollIcon from './components/ScrollIcon';
import SideBar from './components/SideBar';
//import Footer from './components/Footer';
import { Suspense } from 'react';
import animations from './components/Animations';
import React,{ useEffect} from 'react';
import {isMobile} from 'react-device-detect';

import ReactGA from 'react-ga';
const TRACKING_ID = "UA-195650893-1"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID,{debug: true, gaOptions:{ 'anonymize_ip': true}});

ReactGA.pageview(window.location.pathname + window.location.search);
const Spinner = ()=>{
  return(
    <h1>Loading..</h1>
  );
};

function App() {

  useEffect(() => {
    // after load, attach scroll animations
    if (isMobile) {
      animations.createMobileScrollTriggers();
    } else {
      animations.createScrollTriggers();
    }
    
    //document.querySelector('.container').style.display = 'none';
  }, [])

  return (
    <Suspense fallback={<Spinner />}>
      <div className="noisy"></div>
     {/*}  {*/}
     <Intro />
     <ScrollIcon />
      <div className="app">
        <Header />
        
        <SideBar />
           {/*}  {*/}
        <Landing />
        <Before />
        <About />
        <Steps />
        <End />
        <Gallery />
      </div>
     
    </Suspense>
  );
}

export default App;
