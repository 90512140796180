import '../styles/Landing.css';
import landingVideo from '../assets/video/CTQ_F05_Jumprope2.mp4'
import React,{ useEffect} from 'react';

function Landing() {
  

  useEffect(() => {


  }, [])

  return (
    <section className="landing">
      <div className="copy-block">
        <h1>Cut the Quarantine</h1>
        <h2>with <a href='https://locksoflove.org/' target='_blank'>Locks of Love</a></h2>
        <p>Ready to say goodbye to your quarantine hair?<br />Join us in donating it to Locks of Love.</p>
      </div>
      <video  autoPlay loop playsInline muted className="media" src={landingVideo} />
    </section>
  );
}

export default Landing;