import '../styles/End.css';
import media from '../assets/end-hands.png';
import React,{useEffect} from 'react';

import ReactGA from 'react-ga';

function End() {
  

  useEffect(() => {
    document.querySelector('.donation-link').addEventListener('click', (e)=>{
      ReactGA.event({
        category: 'User',
        action: 'Donation link clicked'
      });
    })

  }, [])

  return (
    <section className="end">
      <div className="copy-block">
        <h1>If you’re not at 10 inches of hair yet…</h1>
        <p>Don’t worry, you can keep growing your hair until it’s the right length for donation or you can make a monetary donation to Locks of Love <a className='donation-link' href='https://locksoflove.org/financial-donation/' target='_blank' >here</a>.</p>
      </div>
      <img className="media" src={media} alt="End" />
    </section>
  );
}

export default End;