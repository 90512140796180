import '../styles/ScrollIcon.css';
import scrollIconArrows from '../assets/scroll-icon-arrows.png';
import scrollIconText from '../assets/scroll-icon-text.png';

function ScrollIcon() {
  
  return (
    <div className="scroll-icon">
      <img className="scroll-icon-arrows" src={scrollIconArrows} alt="Scroll" />
      <img className="scroll-icon-text" src={scrollIconText} alt="Scroll" />
    </div>
  );
}

export default ScrollIcon;