import '../styles/Header.css';
import ctqLogo from '../assets/ctq-logo.png';
import lolLogo from '../assets/locks-of-love-logo.png';
import watchButton from '../assets/watch-film-button.png';
import donateButton from '../assets/donate-button.png';
import React,{useEffect} from 'react';
import ReactGA from 'react-ga';

function Header() {
  

  useEffect(() => {
    document.querySelector('.ctqLogo').addEventListener('click', (e)=>{
      ReactGA.event({
        category: 'User',
        action: 'Cut the Quarantine header logo Clicked'
      });
    })

    document.querySelector('.lolLogo').addEventListener('click', (e)=>{
      ReactGA.event({
        category: 'User',
        action: 'Locks of Love header logo Clicked'
      });
    })

    document.querySelector('.watchButton').addEventListener('click', (e)=>{
      ReactGA.event({
        category: 'User',
        action: 'Watch the Film header logo Clicked'
      });
    })


    document.querySelector('.donateButton').addEventListener('click', (e)=>{
      ReactGA.event({
        category: 'User',
        action: 'Donate header logo Clicked'
      });
    })

  }, [])

  return (
    <header className="header">
      <div className="header-brands">
       <a href='' ><img className="ctqLogo" src={ctqLogo} alt="Cut the Quarantine" /></a>
       <a href='https://locksoflove.org/' target='_blank'><img className="lolLogo" src={lolLogo} alt="Locks of Love" /></a>
      </div>
      <div className="header-nav">
      <a href='https://vimeo.com/552593460' target='_blank'><img className="watchButton" src={watchButton} alt="Watch the Film" /></a>
      <a href='https://locksoflove.org/financial-donation/' target='_blank'><img className="donateButton" src={donateButton} alt="Donate" /></a>
      </div>
    </header>
  );
}

export default Header;