import '../styles/Intro.css';
import introImage from '../assets/intro.jpg';
import introScreen from '../assets/intro-screen.png';
import playIcon from '../assets/play-button.png';
import soundIcon from '../assets/sound-button.png';
import soundOffIcon from '../assets/sound-button-off.png';
import skipIcon from '../assets/skip-button.png';
import React,{useState, useEffect} from 'react';
import ReactPlayer from 'react-player/vimeo';
import animations from './Animations';
import {isMobile} from 'react-device-detect';
import ReactGA from 'react-ga';

function Intro() {
  
  const [videoPlaying,setVideoPlaying]=useState(false)
  const [soundOn,setSoundOn]=useState(true)

  useEffect(() => {

    window.scrollTo(0, 0);
    document.body.style.overflowY='hidden';
    
    
    const el = document.querySelector('.intro-splash');
    let cursor = document.querySelector('.intro-play-icon');

    cursor.style.left = '-100px';
    cursor.style.top = '-100px';
    cursor.style.pointerEvents = 'none';
    el.style.cursor = 'none';

    const handleMouseMove = (e)=> {
      cursor.style.left = e.pageX-(window.innerWidth/100 * 5) + "px";
      cursor.style.top = e.pageY-(window.innerWidth/100 * 5) + "px";
      
    }

    el.addEventListener('mousemove', handleMouseMove);

    

    const handleMouseDown = (e)=>{
      setVideoPlaying(true);
      ReactGA.event({
        category: 'User',
        action: 'Clicked on Splash Page'
      });
      // transition to the video
      animations.playTransitionToVideo();
      el.style.cursor = 'default';
      el.removeEventListener('mousemove', handleMouseMove);
      el.removeEventListener('click', handleMouseDown);
      setVideoPlaying(true);

        ReactGA.pageview('video');
      
    }

    setVideoPlaying(false);
    el.addEventListener('click', handleMouseDown);

  }, [])

  const toggleSound = () => {
    setSoundOn( !soundOn )

    ReactGA.event({
      category: 'User',
      action: 'Mute Sound Clicked'
    });
  } 

  const skipVideo = ()=>{
    animations.playTransitionToLanding(0);
    setVideoPlaying(false);
    setTimeout(()=>{setSoundOn(false);},1000);
    
    window.scrollTo(0, 0);
    document.body.style.overflowY='auto';
    ReactGA.event({
      category: 'User',
      action: 'Skip Video Clicked'
    });
    ReactGA.pageview('landing');
  }

  const videoEnded = (e)=>{
    animations.playTransitionToLanding(3);
    setVideoPlaying(false);
    setTimeout(()=>{setSoundOn(false);},1000);
    window.scrollTo(0, 0);
    document.body.style.overflowY='auto';
  }

  const onVideoProgress = (e) =>{
    if ( e.played >= .25 && e.played <.27 ){
       ReactGA.event({
        category: 'User',
        action: 'Viewed Video 25%'
      });
    }

    if ( e.played >= .5 && e.played <.52 ){
        ReactGA.event({
        category: 'User',
        action: 'Viewed Video 50%'
      });
    }

    if ( e.played >= .75 && e.played <.77 ){
        ReactGA.event({
        category: 'User',
        action: 'Viewed Video 75%'
      });
    }

    if ( e.played >= .99 ){
      ReactGA.event({
      category: 'User',
      action: 'Viewed Video 100%'
    });
  }
    
  }

  return (
    <section className="intro">
      <div className="intro-video">
        <div className='player-wrapper'>
          <ReactPlayer 
            url='https://vimeo.com/552593460'
            className='react-player'
            width='100%'
            height='100%'
            playing={videoPlaying}
            onEnded = { videoEnded }
            volume = {1}
            muted = {!soundOn}
            playsinline
            controls = {isMobile}
            onProgress = {onVideoProgress}
          />
        </div>
        <button className="intro-sound-button" onClick={toggleSound}>{(soundOn)?<img alt="turn sound off" src={soundIcon} />:<img alt="turn sound on" src={soundOffIcon} />}</button>
        <button className="intro-skip-button" onClick={skipVideo}><img alt="skip video" src={skipIcon}/></button>
      </div>
      <div className="intro-splash">
        <img className="intro-image" alt="" src={introImage} />
        <img className="intro-screen" alt="" src={introScreen} />
        <img className="intro-play-icon" alt=""src={playIcon} />
      </div>
    </section>
  );
}

export default Intro;
