import '../styles/Gallery.css';
import React,{useEffect} from 'react';
import Footer from './Footer';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ReactGA from 'react-ga';


function Gallery() {
  

  useEffect(() => {


    function loadScript(src, callback) {
      var script  = document.createElement("script");
      script.setAttribute("src", src);
      script.addEventListener("load", callback);
      var head = document.getElementsByTagName("head")[0];
      head.appendChild(script);
    };

    loadScript("https://plugins.flockler.com/embed/1797b71144306f1712024013794e4fd4/1797c6822490e5f8539494aef792764a?autoload=false", function(){
      setTimeout(()=>{
        var embed = window.flcklr.Embeds.create(
          window.flcklr.EmbedConfigs['1797c6822490e5f8539494aef792764a']
        );
        ScrollTrigger.refresh();
      },3000)

      
    });
  }, [])

  return (
    <section className="gallery">
    <div className="copy-block">
      <h1 className="landscape">See some of the people who<br />have #CutTheQuarantine so far!</h1>
      <h1 className="portrait">See some of<br />the people who have<br />#CutTheQuarantine so far!</h1>
      
      <div className="posts"  id="flockler-embed-1797c6822490e5f8539494aef792764a"></div>
      <Footer /> 
    </div>
  </section>
  );
}

export default Gallery;
