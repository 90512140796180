import '../styles/Before.css';
import media from '../assets/big-hair-bg.png';
import React,{useEffect} from 'react';

function Before() {
  

  useEffect(() => {


  }, [])

  return (
    <section className="before">
      <div className="copy-block">
        <h1>Let’s cut it already!</h1>
        <p>It’s been a long year stuck inside. You’ve watched more TV than ever, done more puzzles and Zoom calls than ever, and have more hair than ever.<br />But that last part isn’t all bad. Now you can put it to a good cause by cutting it and donating it to Locks of Love before we all get back to regular life.</p>
      </div>
      <img className="media" src={media} alt="Before" />
    </section>
  );
}

export default Before;