import '../styles/About.css';
import media from '../assets/about-heart.png';
import aboutIcon from '../assets/about.png';
import React,{ useEffect} from 'react';

function About() {
  

  useEffect(() => {


  }, [])

  return (
    <section className="about">
      <div className="hearts"></div>
      <div className="copy-block">
        <img className="title-icon" src={aboutIcon} alt="about title" />
        <h1>Locks<br />of Love</h1>
        <p>Our mission is to return a sense of self, confidence and normalcy to children suffering from hair loss by utilizing donated ponytails to provide the highest quality hair prosthetics to financially disadvantaged children free of charge.</p>
      </div>
      <img className="media" src={media} alt="About" />
    </section>
  );
}

export default About;