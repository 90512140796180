import '../styles/Footer.css';
import lolLogo from '../assets/locks-of-love-logo.png';
import facebookButton from '../assets/footer-fb-icon.png';
import twitterButton from '../assets/footer-twitter-icon.png';
import instagramButton from '../assets/footer-ig-icon.png';
import React,{ useEffect} from 'react';

import ReactGA from 'react-ga';

function Footer() {
  

  useEffect(() => {

    document.querySelector('.facebookButton').addEventListener('click', (e)=>{
      ReactGA.event({
        category: 'User',
        action: 'Facebook social link clicked'
      });
    })

    document.querySelector('.twitterButton').addEventListener('click', (e)=>{
      ReactGA.event({
        category: 'User',
        action: 'Twitter social link clicked'
      });
    })

    document.querySelector('.instagramButton').addEventListener('click', (e)=>{
      ReactGA.event({
        category: 'User',
        action: 'Instagram social link clicked'
      });
    })

    document.querySelector('.lolLogo').addEventListener('click', (e)=>{
      ReactGA.event({
        category: 'User',
        action: 'Locks of Love footer logo clicked'
      });
    })

    

  }, [])

  return (
    <footer className="footer">
      <div className="footer-info">
      <a href='https://locksoflove.org/' target='_blank'><img className="lolLogo" src={lolLogo} alt="Locks of Love" /></a>
       <div className="info-block">
        <h4>LOCKS OF LOVE</h4>
        <p>234 SOUTHERN BLVD<br />
WEST PALM BEACH, FL 33405–2701<br />
PH: (561) 833-7332<br />
©2007–2019 LOCKS OF LOVE, ALL RIGHTS RESERVED.</p>    
       </div>
      </div>
      <div className="footer-social">
        <h4>SOCIAL</h4>
        <div className="footer-social-links">
          <a href='https://www.facebook.com/LocksOfLove/' target='_blank'><img className="facebookButton" src={facebookButton} alt="Facebook" /></a>
          <a href='https://twitter.com/locksoflove' target='_blank'><img className="twitterButton" src={twitterButton} alt="Twitter" /></a>
          <a href='https://www.instagram.com/locksofloveofficial' target='_blank'><img className="instagramButton" src={instagramButton} alt="Instagram" /></a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;