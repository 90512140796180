import '../styles/Steps.css';
import step1Video from '../assets/video/step-1.mp4';
import step1VideoLoop from '../assets/video/step-1-loop.mp4';
import step2Video from '../assets/video/step-2.mp4';
import step2VideoLoop from '../assets/video/step-2-loop.mp4';
import step3Video from '../assets/video/step-3.mp4';
import step3VideoLoop from '../assets/video/step-3-loop.mp4';
import step4Video from '../assets/video/step-4.mp4';
import step4VideoLoop from '../assets/video/step-4-loop.mp4';
import step5Video from '../assets/video/step-5.mp4';
import step5VideoLoop from '../assets/video/step-5-loop.mp4';
import numberIcon1 from '../assets/1.png';
import numberIcon2 from '../assets/2.png';
import numberIcon3 from '../assets/3.png';
import numberIcon4 from '../assets/4.png';
import numberIcon5 from '../assets/5.png';
import twitterButton from '../assets/twitter-button.png';
import facebookButton from '../assets/facebook-button.png';
import instagramButton from '../assets/instagram-button.png';
import React,{ useEffect} from 'react';

import ReactGA from 'react-ga';

function Steps() {
  
  const url  = 'https://cutthequarantine.com/';

  useEffect(() => {
    document.querySelector('.share-link-tw').addEventListener('click', (e)=>{

      ReactGA.event({
        category: 'User',
        action: 'Share on Twitter button clicked'
      });
      
      const hashtags = 'CutTheQuarantine,LocksOfLove,QuarantineHair';
      
      const text = 'Goodbye quarantine and all my quarantine hair! I just Cut The Quarantine with @LocksofLove and you should too! 10 inches of hair gone and going to children experiencing medical hair loss. Go to www.cutthequarantine.com to learn more.';

      window.open("https://twitter.com/intent/tweet?hashtags="+hashtags+"&original_referer="+url+"&ref_src=twsrc%5Etfw&related=locksoflove&text="+text+"&tw_p=tweetbutton","Twitter Share","width=600, height=350, top=" + (window.innerHeight / 2 - 275) + ", left=" + (window.innerWidth / 2 - 225) );
      
    });

    document.querySelector('.share-link-fb').addEventListener('click', (e)=>{

     
        ReactGA.event({
          category: 'User',
          action: 'Share on Facebook button clicked'
        });
      

      const hashtags = 'cutthequarantine,locksoflove';
      const text = 'Goodbye quarantine and all my quarantine hair! I just Cut The Quarantine with @LocksofLove and you should too! 10 inches of hair gone and going to children experiencing medical hair loss. Go to www.cutthequarantine.com to learn more.';

      window.open("https://www.facebook.com/sharer/sharer.php?u="+url+"&display=popup&ref=plugin&src=share_button","Facebook Share","width=600, height=450,top=" + (window.innerHeight / 2 - 275) + ", left=" + (window.innerWidth / 2 - 225) );
    })

    document.querySelector('.donation-requirments-link').addEventListener('click', (e)=>{
      ReactGA.event({
        category: 'User',
        action: 'Donation Requirements PDF link clicked'
      });
    })

  }, [])

  return (
    <>
      <div className="steps-videos">
        <video playsInline muted autoPlay className={`step-video step-1-media`} src={step1Video} />
        <video loop playsInline muted autoPlay className={`step-video step-1-media-loop`} src={step1VideoLoop} />

        <video playsInline muted autoPlay className={`step-video step-2-media`} src={step2Video} />
        <video loop playsInline muted autoPlay className={`step-video step-2-media-loop`} src={step2VideoLoop} />

        <video playsInline muted autoPlay className={`step-video step-3-media`} src={step3Video} />
        <video loop playsInline muted autoPlay className={`step-video step-3-media-loop`} src={step3VideoLoop} />

        <video playsInline muted autoPlay className={`step-video step-4-media`} src={step4Video} />
        <video loop playsInline muted autoPlay className={`step-video step-4-media-loop`} src={step4VideoLoop} />

        <video playsInline muted autoPlay className={`step-video step-5-media`} src={step5Video} />
        <video loop playsInline muted autoPlay className={`step-video step-5-media-loop`} src={step5VideoLoop} />
      
      </div>
      
      <section className={`step step-1`}>
        <div className="copy-block">
          <img className="title-icon" src={numberIcon1} alt="" />
          <h1>Before you cut your hair...</h1>
          <p>Make sure you have at least 10 inches in a ponytail or braid and check out all of the Locks of Love <a href='./docs/Hair Donation Guidelines.pdf' target='_blank' className="donation-requirments-link">donation requirements</a>.</p>
        </div>

      </section>
      <section className={`step step-2`}>
        <div className="copy-block">
          <img className="title-icon" src={numberIcon2} alt="" />
          <h1>Once you’re ready...</h1>
          <p>Put your hair in a ponytail or braid and cut before the start of the hair tie or band. Keep your hair in the ponytail or braid for packaging. </p>
        </div>
      </section>
      <section className={`step step-3`}>
        <div className="copy-block">
          <img className="title-icon" src={numberIcon3} alt="" />
          <h1>Package<br />your hair</h1>
          <p>Locks of Love asks that you place your dry ponytail or braid in a resealable plastic bag and put that inside an appropriate size envelope or folder for the amount of hair you donate.</p>
          <p>Please do not use a letter-sized envelope.</p>
        </div>
      </section>
      <section className={`step step-4`}>
        <div className="copy-block">
          <img className="title-icon" src={numberIcon4} alt="" />
          <h1>Ship your hair</h1>
          <h2>to <a href='https://locksoflove.org/' target='_blank' >Locks of Love</a></h2>
          <h3>Mail your donation to this address:</h3>
          <p>Locks of Love 234 Southern Blvd.<br />West Palm Beach, Florida 33405</p>
        </div>
      </section>
      <section className={`step step-5`}>
        <div className="copy-block">
          <img className="title-icon"src={numberIcon5} alt="" />
          <h1>Share your new look</h1>
          <p>Make sure to get some before and after photos so you can share them with your friends and following and encourage them to #CutTheQuarantine also.</p>
          <p className="desktop">Here are some post templates you can use when you’re ready:</p>
          <div className="share-links">
            <img className={`share-link share-link-tw`} src = {twitterButton} />
            <img className={`share-link share-link-fb`} src = {facebookButton} />
            {/*}<img className={`share-link share-link-ig`} src = {instagramButton} /> {*/}
          </div>
        </div>
      </section>
    </>
  );
}

export default Steps;